<template>
  <div class="create-content-main">
    <div class="create-form">
      <h2 v-if="idCreateTrigger === null || idCreateTrigger === undefined">
        トリガー作成
      </h2>
      <h2 v-else-if="idCreateTrigger !== null || idCreateTrigger !== undefined">
        編集
      </h2>
      <form class="p-4" id="create-content" autocomplete="off">
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold pl-0">NO</label>
          <b-form-input v-model="NO" disabled required></b-form-input>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold pl-0">トリガー名</label>
          <input
            class="form-input col-sm-12"
            type="text"
            v-model="trigger_name"
          />
        </div>
        <hr style="margin-top: 2%; margin-bottom: 2%" />
        <div class="row mb-3 row-input">
          <h3 class="pl-0">トリガー条件設定</h3>
        </div>
        <div class="row mb-3 row-input">
          <label class="col-sm-12 font-weight-bold pl-0">条件設定</label>
          <label
            class="custom-ListLabel"
            v-bind:class="{ checked: index === isChecked }"
            v-on:click="ClickChecked(index)"
            v-for="(item, index) in listLabelConditionSetting"
            :key="index"
            @click="setValueLabel(item.value)"
          >
            {{ item.text }}
          </label>
        </div>
        <div v-if="valueConditionSetting === 0">
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">対象スラグ</label>
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="target_slag"
            />
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">対象スラグ</label>
            <b-form-select
              class="col-sm-2"
              v-model="numberOfTargetSlag"
              :options="listNumberOfTargetSlag"
            ></b-form-select>
          </div>
          <hr style="margin-top: 3%; margin-bottom: 3%" />
          <div class="row mb-3 row-input">
            <h3 class="pl-0">トリガー発動後アクション</h3>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">スラグ付与</label>
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="slag_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              @tag="addTag"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングポップアップ</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="timing_popup"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingPopupOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングメール</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="timing_mail"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingMailOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >コンテンツ付与</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="content_id"
              placeholder="購入付与スラグ追加"
              label="text"
              track-by="value"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              :loading="loadingContent"
              v-bind:class="{ 'error-input': errorContentIdRequired }"
            ></multiselect>
          </div>
        </div>
        <div v-if="valueConditionSetting === 1">
          <div
            class="row mb-3 row-input"
            v-for="(item, index) in listTargetSlag"
            :key="index"
          >
            <label class="col-sm-12 font-weight-bold pl-0"
              >{{ item.text }}（順番{{ item.value }}）</label
            >
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="target_slag"
            />
          </div>
          <div class="row mb-3 row-input">
            <CButton color="success" class="ml-3" @click="addTargetSetting()">
              <font-awesome-icon class="sidebar-icon" :icon="['fas', 'plus']" />
            </CButton>
          </div>
          <hr style="margin-top: 3%; margin-bottom: 3%" />
          <div class="row mb-3 row-input">
            <h3 class="pl-0">トリガー発動後アクション</h3>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">スラグ付与</label>
            <multiselect
              v-model="slag_type_2"
              class="col-sm-12 col-lg-6 p-0"
              tag-placeholder="閲覧付与スラグを追加してください"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              @tag="addTag"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングポップアップ</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="timing_popup"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingPopupOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングメール</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="timing_mail"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingMailOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >コンテンツ付与</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="content_id"
              placeholder="購入付与スラグ追加"
              label="text"
              track-by="value"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              :loading="loadingContent"
              v-bind:class="{ 'error-input': errorContentIdRequired }"
            ></multiselect>
          </div>
        </div>
        <div v-if="valueConditionSetting === 2">
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">対象スラグ</label>
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="target_slag"
            />
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >時間設定（付与後）</label
            >
            <div class="row col-12">
              <div class="row col-sm-12 align-items-center mb-3 col-lg-12">
                <span class="w-100 col-lg-1 text-left text-lg-right px-0"
                  >日時指定</span
                >
                <div class="row w-100 align-items-center col-lg-6 pr-0 ml-lg-2">
                  <!-- <date-picker
                    v-model="dateSelected"
                    type="date"
                    locale="ja"
                    valueType="YYYY-MM-DD"
                  ></date-picker> -->
                  <date-picker
                    class="col-lg-6 pr-sm-0 w-100"
                    v-model="dateSelected"
                    :config="optionsDate"
                    name="date"
                  ></date-picker>
                  <input
                    v-model="timeSelected"
                    class="form-control col-lg-5 ml-lg-2 pr-sm-0 w-100"
                    type="time"
                    locale="ja"
                    valueType="HH:mm"
                  />
                </div>
                <span class="col-auto px-sm-0">時間後に失効する</span>
              </div>
              <div class="row col-sm-12 align-items-center mb-3 col-lg-12">
                <span class="w-100 col-lg-2 text-left text-lg-right px-0"
                  >スラグ追加後カウント</span
                >
                <CInput
                  class="input-hours w-100 mb-0 col-lg-2 pl-0 pl-lg-2"
                  v-model="countAfterSlag"
                  type="number"
                  min="0"
                />
                <span class="col-auto px-0">分後にトリガー発動</span>
              </div>
            </div>
          </div>
          <hr style="margin-top: 3%; margin-bottom: 3%" />
          <div class="row mb-3 row-input">
            <h3 class="pl-0">トリガー発動後アクション</h3>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">スラグ付与</label>
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="slag_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              @tag="addTag"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングポップアップ</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="timing_popup"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingPopupOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングメール</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="timing_mail"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingMailOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >コンテンツ付与</label
            >
            <multiselect
              class="col-sm-12 col-lg-6 p-0"
              v-model="content_id"
              placeholder="購入付与スラグ追加"
              label="text"
              track-by="value"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              :loading="loadingContent"
              v-bind:class="{ 'error-input': errorContentIdRequired }"
            ></multiselect>
          </div>
        </div>
        <div v-if="valueConditionSetting === 3">
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">時間 </label>
            <div class="col-sm-12 px-0">
              <input class="form-input w-100" type="text" v-model="timeFrom" />
              <span
                class="col-auto custom-span text-center p-0 justify-content-center"
                style="line-height: 25px"
                >~</span
              >
              <input
                class="form-input w-100 mt-2"
                type="text"
                v-model="timeTo"
              />
            </div>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">気温 </label>
            <div>
              <input
                class="form-input w-100"
                type="text"
                v-model="temperatureFrom"
              />
              <span
                class="col-auto custom-span text-center p-0 justify-content-center"
                style="line-height: 25px"
                >~</span
              >
              <input
                class="form-input w-100 mt-2"
                type="text"
                v-model="temperatureTo"
              />
            </div>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">場所</label>
            <input class="form-input col-sm-2" type="text" v-model="place" />
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">端末</label>
            <input class="form-input col-sm-2" type="text" v-model="terminal" />
          </div>
          <div class="row mb-3 row-input">
            <h3 class="pl-0">トリガー追加設定</h3>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">条件の確認</label>
            <div class="col-sm-12 px-0">
              <b-form-radio
                v-model="confirmation_of_conditions"
                class="mr-3"
                name="some-radios"
                :value="0"
                >以下条件が全て該当</b-form-radio
              >
              <b-form-radio
                v-model="confirmation_of_conditions"
                class=""
                name="some-radios"
                :value="1"
                >以下条件のいずれかが該当</b-form-radio
              >
            </div>
          </div>
          <div class="row mb-3 row-input">
            <div class="col-sm-12 px-0 col-lg-3">
              <b-form-select
                class="w-100"
                v-model="selected"
                :options="listOptionSelect"
              ></b-form-select>
            </div>
            <div class="col-sm-12 px-0 col-lg-3">
              <CButton
                color="success"
                class="ml-0 ml-lg-3"
                @click="addSelect(selected)"
              >
                <font-awesome-icon
                  class="sidebar-icon"
                  :icon="['fas', 'plus']"
                />
              </CButton>
            </div>
          </div>
          <!-- <div
            class="row mb-3 row-input"
            v-for="(item, index) in listSelect"
            :key="index"
          >
            <b-form-select
              class="col-sm-2"
              v-model="selected"
              :options="listOptionSelect"
            ></b-form-select>
          </div> -->
          <div class="row mb-3">
            <div
              v-for="(ele, ind) in valueDataHTML"
              :key="ind"
              class="col-sm-12 mb-5 px-0"
            >
              <div
                v-for="(item, index) in listHtml[ele]"
                :key="index"
                class="row align-items-center mb-3 col-lg-12 mx-0 px-0"
              >
                <label
                  class="font-weight-bold pl-0 text-nowrap align-items-center mb-0 col-sm-12 col-lg-2"
                  >{{ item.text }}</label
                >
                <b-form-select
                  class="form-control w-3 col-sm-12 col-lg-3"
                  placeholder="条件"
                  :options="listOptionCondition"
                  v-model="item.value_text"
                ></b-form-select>
                <input
                  class="form-input col-sm-12 col-lg-3 col-lg-3"
                  type="text"
                  v-model="item.value_text"
                  v-if="item.type === 'text'"
                />
                <date-picker
                  class="col-sm-12 p-0 col-lg-3"
                  v-if="item.type === 'time'"
                  v-model="item.value_text"
                  :config="optionsDateTime"
                  name="date"
                ></date-picker>
                <span
                  v-if="
                    item.value === 0 ||
                    item.value === 1 ||
                    item.value === 2 ||
                    item.value === 3 ||
                    item.value === 6 ||
                    item.value === 8 ||
                    item.value === 9
                  "
                  class="ml-2 col-lg-1 col-sm-1"
                  >&ensp;秒</span
                >
                <span
                  v-else-if="item.value === 12"
                  class="ml-2 col-lg-1 col-sm-1"
                  >回</span
                >
                <span v-else class="ml-2 col-lg-1 col-sm-1"
                  >&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                >
                <CButton
                  color="success"
                  class="ml-3"
                  @click="removeRecord(index)"
                >
                  <font-awesome-icon
                    class="sidebar-icon"
                    :icon="['fas', 'minus']"
                  />
                </CButton>
              </div>
            </div>
          </div>
          <hr style="margin-top: 3%; margin-bottom: 3%" />
          <div class="row mb-3 row-input">
            <h3 class="pl-0">トリガー発動後アクション</h3>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0">スラグ付与</label>
            <multiselect
              v-model="slag_type_2"
              tag-placeholder="閲覧付与スラグを追加してください"
              placeholder="閲覧付与スラグを追加してください"
              label="slag_name_en"
              track-by="slag_name_en"
              :options="listSlagOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              @tag="addTag"
              :max-height="200"
              select-label="入力して選択する"
              selected-label="選択された"
              deselect-label="入力して削除する"
            >
              <template v-slot:no-options> リストは空です </template>
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングポップアップ</label
            >
            <multiselect
              v-model="timing_popup"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingPopupOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >タイミングメール</label
            >
            <multiselect
              v-model="timing_mail"
              tag-placeholder="購入タイミングポップアップを追加"
              placeholder="購入タイミングポップアップを追加"
              label="text"
              track-by="value"
              :options="listTimingMailOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              v-bind:class="{
                'error-input': errorUserHiddenRequired,
              }"
            >
            </multiselect>
          </div>
          <div class="row mb-3 row-input">
            <label class="col-sm-12 font-weight-bold pl-0"
              >コンテンツ付与</label
            >
            <multiselect
              v-model="content_id"
              placeholder="購入付与スラグ追加"
              label="text"
              track-by="value"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              :loading="loadingContent"
              v-bind:class="{ 'error-input': errorContentIdRequired }"
            ></multiselect>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4">
        <b-button
          v-on:click.prevent="CreateNewCategory()"
          :disabled="isLoading"
          variant="info"
          class="mr-4"
          >登録・更新する</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ja";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { Constants } from "../../utils/constants";
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});
export default {
  name: "CreateCategory",
  components: {},
  data() {
    return {
      idCreateTrigger: this.$route.params.id,
      NO: this.$route.params.id
        ? this.$route.params.id
        : localStorage.getItem(Constants.NO_TRIGGER),
      trigger_name: "",
      listLabelConditionSetting: [
        { value: 0, text: "回数" },
        { value: 1, text: "順番" },
        { value: 2, text: "時間" },
        { value: 3, text: "アクション" },
      ],
      lang: "ja",
      isChecked: 0,
      isLoading: false,
      errorUserHiddenRequired: false,
      errorContentIdRequired: false,
      valueConditionSetting: 0,
      target_slag: "",
      listNumberOfTargetSlag: [{ value: 0, text: "1回" }],
      listTargetSlag: [{ value: 1, text: "対象スラグ" }],
      numberOfTargetSlag: 0,
      listSlagOptions: [],
      slag_type_2: null,
      listTimingPopupOptions: [],
      timing_popup: null,
      timing_mail: null,
      listTimingMailOptions: [],
      content_id: null,
      listContentOptions: [],
      loadingContent: false,
      page: 2,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      dateSelected: "",
      timeSelected: "",
      countAfterSlag: "",
      timeFrom: "",
      timeTo: "",
      temperatureFrom: "",
      temperatureTo: "",
      place: "",
      terminal: "",
      confirmation_of_conditions: 0,
      listSelect: [],
      listOptionSelect: [
        {
          value: 0,
          text: "アクション",
          data: [
            {
              value: 0,
              text: "ログイン時間",
              type: "text",
              value_text: "",
              value_select: "",
            },
            {
              value: 1,
              text: "ログアウト時間",
              type: "text",
              value_text: "",
              value_select: "",
            },
          ],
        },
        {
          value: 1,
          text: "アクション1",
          data: [
            {
              value: 0,
              text: "long",
              type: "text",
              value_text: "",
              value_select: "",
            },
            {
              value: 1,
              text: "phuc",
              type: "text",
              value_text: "",
              value_select: "",
            },
          ],
        },
      ],
      listOptionCondition: [],
      condition: null,
      selected: null,
      optionsDateTime: {
        format: "YYYY-MM-DD HH:mm",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      optionsDate: {
        format: "YYYY-MM-DD",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      listHtml: {
        0: [
          {
            value: 0,
            text: "ログイン時間",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 1,
            text: "ログアウト時間",
            type: "text",
            value_text: "",
            value_select: "",
          },
        ],
        1: [
          {
            value: 2,
            text: "会員登録日時",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 3,
            text: "登録時のリファラ",
            type: "text",
            value_text: "",
            value_select: "",
          },
        ],
        2: [
          {
            value: 4,
            text: "いつ閲覧したか",
            type: "time",
            value_text: "",
            value_select: "",
          },
          {
            value: 5,
            text: "どのページか",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 6,
            text: "閲覧時間",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 7,
            text: "リファラページ",
            type: "text",
            value_text: "",
            value_select: "",
          },
        ],
        3: [
          {
            value: 8,
            text: "ビデオ再生時間",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 9,
            text: "ビデオ閲覧時点",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 10,
            text: "閲覧終了アイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 11,
            text: "閲覧終了日時",
            type: "time",
            value_text: "",
            value_select: "",
          },
          {
            value: 12,
            text: "閲覧終了後再訪",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 13,
            text: "ノート/タグ作成時間",
            type: "time",
            value_text: "",
            value_select: "",
          },
          {
            value: 14,
            text: "無料追加アイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 15,
            text: "購入アイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 16,
            text: "投票アイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 17,
            text: "アンケートに答えたアイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 18,
            text: "予約したアイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 19,
            text: "ポイント交換アイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 20,
            text: "プロフィール編集時間",
            type: "text",
            value_text: "",
            value_select: "",
          },
        ],
        4: [
          {
            value: 21,
            text: "所持ポイント",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 22,
            text: "購入金額",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 23,
            text: "追加アイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
          {
            value: 24,
            text: "購入アイテム",
            type: "text",
            value_text: "",
            value_select: "",
          },
        ],
      },
      valueDataHTML: [0, 1, 2, 3, 4],
    };
  },
  created() {
    const dataRequest = {
      data: {
        shop_id: this.shop_id,
      },
      page: 1,
    };
    this.getALLlistSlag(dataRequest.data);
    this.getListTimingPopUp(dataRequest);
    this.getListTimingmail(dataRequest);
    this.getListContent(dataRequest);
  },
  computed: {
    ...mapGetters([
      "success",
      "message",
      "error",
      "listSlag",
      "listTimingPopup",
      "listTimingMail",
      "listContent",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.isLoading = false;
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name_en: item.slag_name_en,
        slag_url: item.url,
        slag_description: item.description,
      }));
    },
    listTimingPopup() {
      this.listTimingPopupOptions = this.listTimingPopup.data.map((item) => ({
        value: item.id,
        text: item.popup_title_admin,
      }));
    },
    listTimingMail() {
      this.listTimingMailOptions = this.listTimingMail.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
    listContent() {
      document
        .getElementsByClassName("multiselect__content-wrapper")[3]
        .addEventListener("scroll", this.scrollContent);
      let listContent = [];
      listContent = this.listContent.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContent.forEach((element) => {
        this.listContentOptions.push(element);
      });
      this.loadingContent = false;
    },
  },
  methods: {
    ...mapActions({ getALLlistSlag: "getALLlistSlag" }),
    ...mapActions({ getListTimingPopUp: "getListTimingPopUp" }),
    ...mapActions({ getListTimingmail: "getListTimingmail" }),
    ...mapActions({ getListContent: "getListContent" }),
    returnList() {
      this.$router.go(-1);
    },
    scrollContent() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[3]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[3]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[3]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getListContent(requestContent);
      }
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    ClickChecked(index) {
      if (this.isChecked === index) {
        this.isChecked = 1;
      } else {
        this.isChecked = index;
      }
    },
    setValueLabel(value) {
      this.valueConditionSetting = value;
    },
    addTargetSetting() {
      this.listTargetSlag.push({
        text: "対象スラグ",
        value: this.listTargetSlag.length + 1,
      });
    },
    addSelect(value) {
      this.valueDataHTML.push(value);
      console.log(this.valueDataHTML);
    },
    removeRecord(index) {
      this.listHtml.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-span {
  @media (max-width: 420px) {
    display: none;
  }
}
</style>
